import React, {FC} from 'react';
import {useNavigate} from "react-router-dom";
import {storiesListClasses, StyledStoriesList} from "./StyledStoriesList";
import {Card, CardActionArea, CardContent, CardMedia, Button, Grid, Typography} from "@mui/material";
import {primary} from "../../common/theme";
import {IStory} from "../interfaces/IStory";

import location from "../../assets/location.png";
import { NavigationRoutes } from "../../routes";

const pdfFileUrl = '/pdfs/LSP_MAGAZINE_VOLUME_I.pdf'; // Replace with the actual path to your PDF file
const openPDF = () => {
    window.open(pdfFileUrl, '_blank');
};

interface IStoriesList  {
    stories: IStory[]
}

export const StoriesList: FC<IStoriesList> = ({stories}) => {
    const navigate = useNavigate();
    return <StyledStoriesList container spacing={8} columnSpacing={4} className={storiesListClasses.root} >

        <Grid item sm={12} sx={{paddingTop:"10px!important"}}>
            <Typography variant={"h2"} component="div" sx={{display:"block", color: primary, margin: "70px 0 20px 0", zIndex: 100, textAlign:"left", fontSize:"36px", lineHeight:"42px", fontWeight:"400"}}>
            Download all the Stories
            </Typography>
        </Grid>
        <Grid item sm={12} sx={{paddingTop:"3px!important"}}>
            <Button variant={"contained"} sx={{padding:"14px 25px", height:"auto!important", minHeight:"48px", lineHeight:"20px"}} className={storiesListClasses.button} onClick={openPDF}>
                Volume 1, 2023, SRHR-UHC LSP Magazine
            </Button>
        </Grid>

        {stories.map((story) => (
            <Grid item xs={12} md={6} lg={4} key={story.id}>
                <Card className={storiesListClasses.card}>
                    <CardActionArea onClick={()=> {
                        navigate(`/${NavigationRoutes.Story}/${story.id}`);
                        window.scrollTo(0, 0);
                    }}>
                        <CardMedia
                            component="img"
                            image={story.image}
                            alt={story.imagealt}
                            className={storiesListClasses.cardImg}
                        />
                        {story.keywords && (
                            <Grid container className={storiesListClasses.keywords} wrap={"wrap"}>
                                {story.keywords.map((keyword, index) => (
                                    <Typography key={index} className={storiesListClasses.keywordtag}>
                                        {keyword}
                                    </Typography>
                                ))}
                            </Grid>
                        )}
                        <Grid container className={storiesListClasses.cardLocation}>
                            <img src={location} alt={"location"}/>
                            <Typography variant={"h6"}>{story.country}</Typography>
                        </Grid>
                        <CardContent className={storiesListClasses.cardContent}>
                            <Typography gutterBottom variant="h6" className="storyHeader">
                                {story.header}
                            </Typography>
                            <Button variant={"contained"} className={storiesListClasses.button}>
                                Read Full Story
                            </Button>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        ))}
    </StyledStoriesList>;
};
