import React from 'react';
import './App.css';
import {Home} from "./Home/Home";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import { About } from './About/About';
import {NavigationRoutes} from "./routes";
import {Story} from "./Stories/Story/Story";
import { theme } from "./common/theme";
import { ThemeProvider } from "@mui/material";
import { Stories } from "./Stories/Stories";
import { VideoTelling } from "./VideoTelling/VideoTelling";
import { Resources } from "./Resources/Resources";
import { Login } from "./Login/Login";
import { Admin } from "./Admin/Admin";
import ReactGA from "react-ga4";
ReactGA.initialize("G-BMDVHX07PR");

function App() {
  return (
    <div className="App">
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path={`/${NavigationRoutes.Default}`} element={<Home/>} />
                    <Route path={`/${NavigationRoutes.Login}`} element={<Login />} />
                    <Route path={`/${NavigationRoutes.Admin}`} element={<Admin />} />
                    <Route path={`/${NavigationRoutes.About}`} element={<About/>} />
                    <Route path={`/${NavigationRoutes.Stories}`} element={<Stories/>} />
                    <Route path={`/${NavigationRoutes.VideoTelling}`} element={<VideoTelling/>} />
                    <Route path={`/${NavigationRoutes.Stories}/:country`} element={<Stories/>} />
                    <Route path={`/${NavigationRoutes.Stories}/:country/:topic`} element={<Stories/>} />
                    <Route path={`/${NavigationRoutes.Story}/:storyId`} element={<Story/>} />
                    <Route path={`/${NavigationRoutes.Resources}`} element={<Resources/>} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </div>
  );
}

export default App;
