import {Grid, styled} from "@mui/material";
import { primary } from "../../common/theme";

const STORIES_CAPTION_PREFIX = "StoriesCaption";

export const storiesCaptionClasses = {
    root: `${STORIES_CAPTION_PREFIX}-root`,
    leftCol: `${STORIES_CAPTION_PREFIX}-leftCol`,
    title: `${STORIES_CAPTION_PREFIX}-title`,
    titleWrapper: `${STORIES_CAPTION_PREFIX}-titleWrapper`,
    subBar: `${STORIES_CAPTION_PREFIX}-subBar`,
    smallBrush: `${STORIES_CAPTION_PREFIX}-smallBrush`,
    description: `${STORIES_CAPTION_PREFIX}-description`,
    mainContainer: `${STORIES_CAPTION_PREFIX}-mainContainer`,
    nextStory: `${STORIES_CAPTION_PREFIX}-nextStory`,
    rightCol: `${STORIES_CAPTION_PREFIX}-rightCol`,
    brush: `${STORIES_CAPTION_PREFIX}-brush`,
    card: `${STORIES_CAPTION_PREFIX}-card`,
    cardLocation: `${STORIES_CAPTION_PREFIX}-cardLocation`,
    cardImg: `${STORIES_CAPTION_PREFIX}-cardImg`,
    cardContent: `${STORIES_CAPTION_PREFIX}-cardContent`,
};

export const StyledStoriesCaption = styled(Grid)(({ theme }) => ({
    [`&.${storiesCaptionClasses.root}`]: {
        background: "radial-gradient(at 10% 100%, #375271 0%, #0F2F4B 34%) 0% 0% no-repeat",
        color: "#fff",
    },
    [`& .${storiesCaptionClasses.mainContainer}`]: {
       paddingTop: "150px", 
       paddingBottom: "55px", 
       position: "relative",
    },
    [`& .${storiesCaptionClasses.leftCol}`]: {
        paddingTop: "80px"
    },
    [`& .${storiesCaptionClasses.titleWrapper}`]: {
        position: "relative"
    },
    [`& .${storiesCaptionClasses.title}`]: {
        fontSize: "32px",
        fontWeight: "normal",
        lineHeight: "normal",
        padding: "0 0 30px 30px",
        position: "relative",
        zIndex: 2
    },
    [`& .${storiesCaptionClasses.subBar}`]: {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    },
    [`& .${storiesCaptionClasses.smallBrush}`]: {
        position: "absolute",
        left: "-5px",
        bottom: "25px",
        zIndex: 1
    },
    [`& .${storiesCaptionClasses.description}`]: {
        fontSize: "16px",
        lineHeight: "24px",
        opacity: "0.8",
        marginBottom: "40px"
    },
    [`& .${storiesCaptionClasses.nextStory}`]: {
        justifyContent: "end",
        alignItems: "center",
        [`& h5, h6`]: {
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: "normal"
        },
        [`& h6`]: {
            opacity: "0.8",
            marginLeft: "25px"
        },
        [`& svg`]: {
            width: "16px",
            color: "#E38A15",
            marginLeft: "40px"
        },
    },
    [`& .${storiesCaptionClasses.rightCol}`]: {
        position: "relative",
        paddingTop: "85px",
        alignItems: "baseline",
        justifyContent: "center",
        flexDirection: "column",
    },
    [`& .${storiesCaptionClasses.brush}`]: {
        position: "absolute",
        top: "-35px",
        left: "-20px",
        zIndex: 91
    },
    [`& .${storiesCaptionClasses.card}`]: {
        background: "transparent",
        color: "#fff",
        boxShadow: "none",
        position: "relative",
        zIndex: 92
    },
    [`& .${storiesCaptionClasses.cardLocation}`]: {
        position: "absolute",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
        width: "45%",
        top: 5,
        left: 5,
        background: `${primary}8F`,
        borderRadius: "5px",
        justifyContent: "space-around",
    },
    [`& .${storiesCaptionClasses.cardImg}`]: {
        height: "250px",
        borderRadius: "8px"
    },
    [`& .${storiesCaptionClasses.cardContent}`]: {
        padding: "5px 0",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px"
        },
    },
}));
