import { useEffect, useState, useCallback } from "react";
import { storiesClasses, StyledStories } from "./StyledStories";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { StoriesCaption } from "./StoriesCaption/StoriesCaption";
import { StoriesList } from "./StoriesList/StoriesList";
import { Container } from "@mui/material";
import { Mode } from "../interfaces/mode";
import { stories } from "./stories-items";
import { IStory } from "./interfaces/IStory";
import { useParams } from "react-router-dom";
import { useGA } from "../common/hooks";

export const Stories = () => {
    useGA("Stories");
    const { country, topic } = useParams();
    const [innerStories, setStories] = useState(stories);

    const urlChanged = useCallback(() => {
        let storyList = [ ...stories ];

        if (country && country !== "all") {
            storyList = storyList.filter((story: IStory) => story.country === country);
        }

        if (topic && topic !== "all") {
            storyList = storyList.filter((story: IStory) => story.keywords.some(keyword => keyword.toLowerCase().replace(/[^a-z0-9]+/g, '-') === topic));
        }

        setStories(storyList);
    }, [country, topic]);

    useEffect(() => {
        if (!!country || !!topic) urlChanged();
    }, [country, topic, urlChanged]);

    return <ViewContainer mode={Mode.Dark}>
        <StyledStories className={storiesClasses.root}>
            <StoriesCaption />
            <Container maxWidth={"lg"}>
                <StoriesList stories={innerStories} />
            </Container>
        </StyledStories>
    </ViewContainer>;
};
