import { videoTellingCaptionClasses as classes, StyledVideoTellingCaption } from "./StyledVideoTellingCaption";
import { Container, Grid, Typography } from "@mui/material";
import videoHero from "../../assets/videoHero.jpg";

export const VideoTellingCaption = () => {
    const mainContainerStyle = {
        backgroundImage: 'url('+videoHero+')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    const barContainerStyle = {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    }

    return <StyledVideoTellingCaption className={classes.root}>
        <Grid item sx={mainContainerStyle} className="heroClass">
            <Container maxWidth={"lg"}>
                <Grid container sx={{paddingTop: "150px", paddingBottom: "55px", position: "relative", minHeight: {lg: "calc(100vh - 228px)"} }}>
                    <Grid item md={8} lg={6}>
                        <Grid container sx={{position: "relative", backgroundColor:"rgba(255,255,255,0.75)", padding:"40px 20px 20px", marginBottom: "40px" }}>
                            <Typography variant={"caption"} component={"div"} sx={{color: "#011E3F", textAlign: "left", zIndex: 100,  position: "relative"}}>
                            Video Telling
                            </Typography>
                            <Typography sx={{color: "#011E3F", margin: "30px 0 20px", textAlign: "left", fontSize: "20px"}}>
                            Grab your headphones and listen to LSP authors share their stories and lessons on increasing the coverage of SRHR services. Each author worked hand-in-hand with a speaking coach to tell these stories.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* FIXME: Dont do this*/}
                <Grid item sx={{"height": "50px", width: "100%"}} />
            </Container>
        </Grid>
        <Grid item sx={barContainerStyle}>
            <Container maxWidth={"xl"}>
                <Grid container sx={{padding: "15px 0", position: "relative" }}>
                    <Grid item lg={12}>
                        <Grid container sx={{position: "relative", padding:"0", justifyContent: "center" }}>
                            <Typography variant={"h3"} sx={{color: "#FFF", fontWeight:"bold", margin: "0", textAlign: "center", fontSize: "20px", maxWidth: "990px"}}>
                            The 2024 ERG call is now closed. Thank you for your submissions! Check out our latest implementation stories <a href="/stories" style={{color: "#FFF"}}>here</a>. If you have a story to share, please complete <a href="https://form.jotform.com/241827346668064" target="_blank" rel="noreferrer" style={{color: "#FFF"}}>this form</a>.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </StyledVideoTellingCaption>;
};
